<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button type="danger" @click="createdItem">新 增</el-button>
      <el-input v-model="tableQuery.name" style="width: 200px;margin: 0 10px" placeholder="请输入名称"></el-input>
      <el-button :loading="tableLoading" type="danger" @click="selectRepairCardPage">搜 索</el-button>
    </div>
    <el-table
        :data="tableData" class="table" height="570px"
        row-key="id" border v-loading="tableLoading">
      <el-table-column prop="id" label="ID" width="140"/>
      <el-table-column prop="name" label="名称" width="200"/>
      <el-table-column label="补卡时限" width="280">
        <template #default="scope">
          可申请过去{{scope.row.lastDeadline }}天内的补卡。
        </template>
      </el-table-column>
      <el-table-column prop="frequency" label="补卡次数">
        <template #default="scope">
          每月可申请{{scope.row.frequency }}次补卡，每月一日重新计算。
        </template>
      </el-table-column>
      <el-table-column prop="addTime" label="创建时间" width="200"/>
      <el-table-column label="允许补卡" width="90"  align="center">
        <template #default="scope">
          <el-switch
              v-model="scope.row.isAllow"
              active-color="#ff4d4f"  :active-value="1"
              inactive-color="#C0C4CC" :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="110" align="center">
        <template #default="scope">
          <div style="margin-right: 4px;">
            <el-button type="text" size="small" style="margin-right: 10px" @click.prevent="updateItem(scope.row)">编辑</el-button>
            <el-popconfirm title="确定要删除该班次吗？" @confirm="deleteItem(scope.row.id)">
              <el-button slot="reference" type="text" size="small" style="color: red;">删除</el-button>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: right;margin: 20px 10px"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="9"
        @current-change="changePage"
        layout="total, prev, pager, next, jumper"
        :total="tableTotal">
    </el-pagination>
    <created-repair-card @refresh="selectRepairCardPage"  ref="created"/>
    <update-repair-card @refresh="selectRepairCardPage" ref="update"/>
  </div>
</template>

<script>
import {selectRepairCardPage,deleteRepairCard} from '/src/api/attendance/attendanceRules'
import CreatedRepairCard from "./CreatedRepairCard";
import UpdateRepairCard from "./UpdateRepairCard";
export default {
  name: "RepairCard",
  components:{
    CreatedRepairCard,UpdateRepairCard
  },
  data() {
    return {
      tableQuery:{
        name:"",
        pageIndex:0,
        pageSize:9,
      },
      tableTotal:0,
      tableData:[],
      roleGroup:[],
      tableLoading:false,
    }
  },
  methods:{
    selectRepairCardPage(){
      this.tableLoading = true;
      selectRepairCardPage(this.tableQuery).then(val => {
        this.tableLoading = false;
        if (val.status==200){
          this.tableData = val.data.list;
          this.tableTotal = val.data.total;
        }
      })
    },
    changePage(index){
      this.tableQuery.pageIndex = index;
      this.selectRepairCardPage();
    },
    createdItem(){
      this.$refs.created.openDialog();
    },
    updateItem(item){
      this.$refs.update.openDialog(item);
    },
    deleteItem(id){
      deleteRepairCard(id).then(val => {
        if (val.status == 200){
          this.selectRepairCardPage();
          this.$message({message: '删除成功！', type: 'success'});
        }
      })
    }
  },
  created() {
    this.selectRepairCardPage();
  }
}
</script>

<style scoped>
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 0px 10px 6px;
}
</style>