<template>
  <div>
    <el-dialog title="新增补卡规则" :visible.sync="createDialog" width="1000px">
      <div style="margin:0 30px;height: 340px;overflow: auto">
        <el-form label-position="top" :model="repairCard" :rules="rules" ref="formRef" label-width="110px" class="demo-ruleForm">
          <el-row>
            <el-col :span="8">
              <el-form-item label-width="100px" label="规则名称" prop="name">
                <el-input v-model="repairCard.name" style="width: 210px" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="应用范围" prop="attendanceGroupList">
                <el-select collapse-tags multiple v-model="repairCard.attendanceGroupIds" filterable placeholder="请选择">
                  <el-option v-for="item in attendanceGroup" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="管理老师" prop="directorId">
                <el-select
                    v-model="repairCard.directorId"
                    filterable placeholder="请输入" :loading="coachLoading"
                    remote reserve-keyword :remote-method="selectCoach">
                  <el-option v-for="(coach,index) in coachList" :key="index" :label="coach.name" :value="coach.id">
                    {{coach.id}} - {{coach.name}}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label-width="100px" label="允许补卡">
                <button style="color: #606266;padding-left: 8px;width: 70%" class="set-button">
                  <el-switch
                      v-model="repairCard.isAllow"
                      active-color="#ff4d4f"  :active-value="1"
                      inactive-color="#C0C4CC" :inactive-value="0"
                  ></el-switch>
                </button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="补卡次数">
                <button style="color: #606266;padding-left: 8px;width: 80%" class="set-button">
                  每月<el-input-number  :disabled="repairCard.isAllow==0" style="width: 100px;margin: 0 10px" size="mini" v-model="repairCard.frequency"></el-input-number>
                  次补卡
                </button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label-width="100px" label="补卡时限">
                <button style="color: #606266;padding-left: 8px;width: 89%" class="set-button">
                  可申请<el-input-number :disabled="repairCard.isAllow==0" style="width: 100px;margin: 0 10px" size="mini" v-model="repairCard.lastDeadline"></el-input-number>
                  天内的补卡
                </button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="createDialog = false">取 消</el-button>
          <el-button type="danger" @click="createdItem">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import {selectCoach} from '/src/api/contacts/user'
import {selectAttendanceGroupByPage} from "/src/api/attendance/attendanceGroup";
import {createdRepairCard} from "/src/api/attendance/attendanceRules"
export default {
  name: "CreatedRepairCard",
  data() {
    return {
      repairCard:{
        name:"",
        attendanceGroupIds:[],
        directorId:1,
        isAllow:0,
        frequency:0,
        lastDeadline:0
      },
      rules:{
        name: [{required: true, message: "请输入名称！", trigger: "blur"}],
      },
      coachList:[],
      coachLoading:false,
      attendanceGroup:[],
      createDialog:false
    }
  },
  methods: {
    openDialog() {
      this.createDialog = true;
    },
    selectCoach(val){
      this.coachLoading = true;
      selectCoach({coachName:val ? val:''}).then(val=>{
        this.coachLoading = false;
        this.coachList = val.data;
      })
    },
    /**
     * 创建补卡规则
     */
    createdItem(){
      createdRepairCard(this.repairCard).then(val=>{
        if (val.status){
          this.$emit('refresh');
          this.createDialog = false;
          this.$message({message: '新增成功！', type: 'success'});
        }
      })
      console.log(this.repairCard)
    }
  },
  created() {
    this.selectCoach();
    let query = {
      pageIndex:0,
      pageSize:100,
    }
    selectAttendanceGroupByPage(query).then(val=>{
      if (val.status == 200){
        this.attendanceGroup = val.data.list;
      }
    })
  }
}
</script>

<style scoped>
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
  color: #ff4d4f; /* 输入框中选中项文字颜色 */
}
.set-button {
  background-color: white;
  width: 210px;
  border: #d8dbe2 1px solid;
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  color: #c1c1c1;
  text-align: left;
  display: flex;
  align-items: center;
}
.set-button:hover {
  border: #c0c4cc 1px solid;
  display: flex;
  align-items: center;
}
</style>