import request from '/src/api/request.js'

/**
 *  补卡规则-查询分页数据
 */
export function selectRepairCardPage(query) {
    return request({
        url: 'sys/cardReplacementRules/GetCardReplacementRulesByPage',
        method: 'POST',
        data: query,
    })
}

/**
 * 创建补卡规则
 */
export function createdRepairCard(item) {
    return request({
        url: 'sys/cardReplacementRules/insertCardReplacementRules',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: item,
    })
}






/**
 * 删除补卡规则
 */
export function deleteRepairCard(cardReplacementRulesId) {
    return request({
        url: 'sys/cardReplacementRules/deleteCardReplacementRules',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: cardReplacementRulesId,
    })
}
