import request from '/src/api/request.js'

/**
 *  考勤组-查询分页数据
 */
export function selectAttendanceGroupByPage(query) {
    return request({
        url: 'sys/attendanceGroup/GetAttendanceGroupByPage',
        method: 'POST',
        data: query,
    })
}

/**
 * 考勤组-详情
 */
export function selectAttendanceGroupDetails(attendanceGroupId) {
    return request({
        url: 'sys/attendanceGroup/GetAttendanceGroupDetails',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: attendanceGroupId,
    })
}

/**
 * 考勤组-新增
 */
export function createdAttendanceGroup(item) {
    return request({
        url: 'sys/attendanceGroup/insertAttendanceGroup',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: item,
    })
}

/**
 * 考勤组-更新
 */
export function updateAttendanceGroup(item) {
    return request({
        url: 'sys/attendanceGroup/updateAttendanceGroup',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: item,
    })
}

/**
 * 删除考勤组
 */
export function deleteAttendanceGroup(attendanceGroupId) {
    return request({
        url: 'sys/attendanceGroup/deleteAttendanceGroup',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: attendanceGroupId,
    })
}

/**
 * 蓝牙分页
 */
export function getBluetoothByPage(query) {
    return request({
        url: 'sys/bluetooth/GetBluetoothByPage',
        method: 'POST',
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        data: query,
    })
}
